/* App.css */

/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    /* Light gray background */
    color: #343a40;
    /* Dark gray text */
}
        
.header-bg {
    background-image: url('../public/image_bg_blue_gradient.png');
    background-size: cover;
    background-position: center;
    padding: 1rem 0;
}

.profile-image {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 120px;
    height: 120px;
}
        
.section-transition {
    position: relative;
    height: 20px;
    margin-top: -20px;
    background: linear-gradient(to bottom, transparent, white);
    z-index: 10;
}

/* Menu */
.menu {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 1rem;
}

.menu-item {
  padding: .5rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.menu-item.selected {
    background-color: #e9ecef;
}

.menu-item:hover {
  background-color: #dee2e6;
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 1rem;
}

/* Sections */
section {
    padding: 0rem 1rem 1rem 1rem;
    /* margin: 1rem 0; */
    /* background-color: white; */
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

section h1 {
    color: #333; /* Dark gray text */
    font-size: 2em; /* Larger font size */
    font-weight: bold; /* Bold text */
    text-align: center; /* Center the text */
    margin-bottom: 10px; /* Add space below the h1 */
  }

section h2 {
    font-size: 1.5rem;
    border-bottom: 1px solid #ccc; /* Light gray line */
    margin-top: 0.5em; /* Adjust spacing */
    margin-bottom: 0.5em;
}

section p {
    margin-bottom: 1rem;
}

section ul {
    /* list-style-type: none; */
    padding: 0;
    margin-bottom: 1rem;
}

section li {
    margin-left: 2rem;
    background-color: #e9ecef;
    padding: 0.25rem;
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
}

section li:hover {
    background-color: #dee2e6;
}

section a {
    color: #007bff;
    text-decoration: none;
}

section a:hover {
    text-decoration: underline;
}

/* Footer */
footer {
    text-align: center;
    padding: 1rem;
    background-color: #343a40;
    color: white;
}